import { z } from 'zod';

export type BodilyInjuryPerspective = 'front' | 'back';

export type BodilyInjuryRegion =
  | 'head'
  | 'shoulder_left'
  | 'shoulder_right'
  | 'arm_left'
  | 'arm_right'
  | 'hand_left'
  | 'hand_right'
  | 'chest'
  | 'torso'
  | 'upper_leg_left'
  | 'upper_leg_right'
  | 'lower_leg_left'
  | 'lower_leg_right'
  | 'foot_left'
  | 'foot_right'
  | 'back';

// 0 = No pain; 10 = Max pain
type PainRating = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;

export interface RegionOption<OptionKey> {
  key: OptionKey;
  data: { [field: string]: boolean };
  manual_input?: string;
}

export type Region =
  | {
      key: 'head';
      options: RegionOption<HeadOptionKey>[];
      current_pain_rating?: PainRating;
    }
  | {
      key: 'shoulder_left' | 'shoulder_right';
      options: RegionOption<ShoulderOptionKey>[];
      current_pain_rating?: PainRating;
    }
  | {
      key: 'arm_left' | 'arm_right';
      options: RegionOption<ArmOptionKey>[];
      current_pain_rating?: PainRating;
    }
  | {
      key: 'hand_left' | 'hand_right';
      options: RegionOption<HandOptionKey>[];
      current_pain_rating?: PainRating;
    }
  | {
      key: 'chest';
      options: RegionOption<ChestOptionKey>[];
      current_pain_rating?: PainRating;
    }
  | {
      key: 'torso';
      options: RegionOption<TorsoOptionKey>[];
      current_pain_rating?: PainRating;
    }
  | {
      key: 'back';
      options: RegionOption<BackOptionKey>[];
      current_pain_rating?: PainRating;
    }
  | {
      key: 'upper_leg_left' | 'upper_leg_right';
      options: RegionOption<UpperLegOptionKey>[];
      current_pain_rating?: PainRating;
    }
  | {
      key: 'lower_leg_left' | 'lower_leg_right';
      options: RegionOption<LowerLegOptionKey>[];
      current_pain_rating?: PainRating;
    }
  | {
      key: 'foot_left' | 'foot_right';
      options: RegionOption<FootOptionKey>[];
      current_pain_rating?: PainRating;
    };

export interface BodilyInjuryPayload {
  regions: Region[];
}

export type BodilyInjuryDisplayMode =
  | 'auto'
  | 'workers_comp'
  | 'property'
  | 'liability';

export const DisplayMode: { [k: string]: BodilyInjuryDisplayMode } = {
  AUTO: 'auto',
  WORKERS_COMP: 'workers_comp',
  PROPERTY: 'property',
  LIABILITY: 'liability',
};

export type TemplateTabs = {
  key: string;
  label: string;
  display_in_modes?: BodilyInjuryDisplayMode[];
};

export type TemplateOption<Key> = {
  key: Key;
  tabs: string[];
  label: string;
  popular_in_modes?: BodilyInjuryDisplayMode[];
  hidden_in_modes?: BodilyInjuryDisplayMode[];
  display_in_modes?: BodilyInjuryDisplayMode[];
  hide_if_low_severity?: BodilyInjuryDisplayMode[];
  followups?: { key: string; label: string }[];
};

export interface RegionTemplate<OptionKey> {
  tabs: TemplateTabs[];
  options: TemplateOption<OptionKey>[];
}

export type BodilyInjuryRegionTemplates =
  | 'head'
  // | 'neck'
  | 'shoulder'
  | 'arm'
  | 'hand'
  | 'chest'
  | 'torso'
  | 'back'
  | 'upper_leg'
  | 'lower_leg'
  | 'foot';

export type RegionTemplates = {
  head: RegionTemplate<HeadOptionKey>;
  shoulder: RegionTemplate<ShoulderOptionKey>;
  arm: RegionTemplate<ArmOptionKey>;
  hand: RegionTemplate<HandOptionKey>;
  chest: RegionTemplate<ChestOptionKey>;
  torso: RegionTemplate<TorsoOptionKey>;
  back: RegionTemplate<BackOptionKey>;
  upper_leg: RegionTemplate<UpperLegOptionKey>;
  lower_leg: RegionTemplate<LowerLegOptionKey>;
  foot: RegionTemplate<FootOptionKey>;
};

export type BodilyInjuryRegionTabs =
  | { key: 'head'; label: string; data: RegionTemplate<HeadOptionKey> }
  | {
      key: 'shoulder_left' | 'shoulder_right';
      label: string;
      data: RegionTemplate<ShoulderOptionKey>;
    }
  | {
      key: 'arm_left' | 'arm_right';
      label: string;
      data: RegionTemplate<ArmOptionKey>;
    }
  | {
      key: 'hand_left' | 'hand_right';
      label: string;
      data: RegionTemplate<HandOptionKey>;
    }
  | { key: 'chest'; label: string; data: RegionTemplate<ChestOptionKey> }
  | { key: 'torso'; label: string; data: RegionTemplate<TorsoOptionKey> }
  | { key: 'back'; label: string; data: RegionTemplate<BackOptionKey> }
  | {
      key: 'upper_leg_left' | 'upper_leg_right';
      label: string;
      data: RegionTemplate<UpperLegOptionKey>;
    }
  | {
      key: 'lower_leg_left' | 'lower_leg_right';
      label: string;
      data: RegionTemplate<LowerLegOptionKey>;
    }
  | {
      key: 'foot_left' | 'foot_right';
      label: string;
      data: RegionTemplate<FootOptionKey>;
    };

export const armOptionsKeyZod = z.union([
  z.literal('cut_scrape'),
  z.literal('bruise'),
  z.literal('puncture'),
  z.literal('airbag_burns'),
  z.literal('light_bleeding'),
  z.literal('uncontrolled_bleeding'),
  z.literal('dislocated'),
  z.literal('hyperextension'),
  z.literal('sprain_strain'),
  z.literal('soreness'),
  z.literal('broken_bone'),
  z.literal('bruised_bone'),
  z.literal('severed'),
  z.literal('amputation'),
  z.literal('crushed'),
  z.literal('burn_scald'),
  z.literal('dermatitis'),
  z.literal('infection'),
]);

export type ArmOptionKey = z.TypeOf<typeof armOptionsKeyZod>;

export const backOptionKeyZod = z.union([
  z.literal('vertebrae_fracture'),
  z.literal('herniated_disk'),
  z.literal('back_nerve_pinch'),
  z.literal('neck_pain'),
  z.literal('other_bone_head'),
  z.literal('puncture'),
  z.literal('spinal_nerve_damage'),
  z.literal('spinal_nerve_damage_neck'),
  z.literal('sprain_strain'),
  z.literal('sprain_strain_upper'),
  z.literal('tail_bone_fracture'),
  z.literal('tail_bone_bruise'),
  z.literal('spinal_nerve_damage_back'),
  z.literal('sprain_strain_lower'),
  z.literal('soreness_pain'),
  z.literal('vertebrae_fracture_back'),
  z.literal('vertebrae_fracture_neck'),
  z.literal('whiplash'),
]);

export type BackOptionKey = z.TypeOf<typeof backOptionKeyZod>;

export const chestOptionKeyZod = z.union([
  z.literal('cut_scrape'),
  z.literal('puncture'),
  z.literal('bruise'),
  z.literal('airbag_burns'),
  z.literal('seatbelt_bruise'),
  z.literal('light_bleeding'),
  z.literal('uncontrolled_bleeding'),
  z.literal('broken_rib'),
  z.literal('bruised_rib'),
  z.literal('broken_collarbone'),
  z.literal('bruised_collarbone'),
  z.literal('soreness'),
  z.literal('silicosis'),
  z.literal('asbestosis'),
  z.literal('blackLung'),
  z.literal('byssinosis'),
  z.literal('rupture'),
  z.literal('heartAttack'),
  z.literal('anginaPectoris'),
  z.literal('burn_scald'),
  z.literal('dermatitis'),
  z.literal('infection'),
  z.literal('heart_inflammation'),
  z.literal('heart_laceration'),
  z.literal('heart_infection'),
  z.literal('lung_inflammation'),
  z.literal('lung_laceration'),
  z.literal('lung_infection'),
  z.literal('neck_pain'),
  z.literal('other_bone_head'),
  z.literal('vertebrae_fracture'),
  z.literal('whiplash'),
]);
export type ChestOptionKey = z.TypeOf<typeof chestOptionKeyZod>;

export const footOptionKeyZod = z.union([
  z.literal('cut_scrape'),
  z.literal('bruise'),
  z.literal('puncture'),
  z.literal('light_bleeding'),
  z.literal('uncontrolled_bleeding'),
  z.literal('broken_toe'),
  z.literal('hyperextension_toe'),
  z.literal('sprain_strain_ankle'),
  z.literal('sprain_strain_toes'),
  z.literal('soreness'),
  z.literal('severed_foot'),
  z.literal('amputation_foot'),
  z.literal('crushed_foot'),
  z.literal('severed_toes'),
  z.literal('amputation_toe'),
  z.literal('crushed_toes'),
  z.literal('broken_ankle'),
  z.literal('hyperextension_ankle'),
  z.literal('broken_bone'),
  z.literal('bruised_bone'),
  z.literal('burn_scald'),
  z.literal('dermatitis'),
  z.literal('infection'),
]);
export type FootOptionKey = z.TypeOf<typeof footOptionKeyZod>;

export const handOptionKeyZod = z.union([
  z.literal('cut_scrape'),
  z.literal('bruise'),
  z.literal('puncture'),
  z.literal('airbag_burns'),
  z.literal('light_bleeding'),
  z.literal('uncontrolled_bleeding'),
  z.literal('broken_finger'),
  z.literal('hyperextension_finger'),
  z.literal('sprain_finger'),
  z.literal('severed_finger'),
  z.literal('amputation_finger'),
  z.literal('crushed_finger'),
  z.literal('broken_wrist'),
  z.literal('carpal_tunnel'),
  z.literal('bruised_bone'),
  z.literal('broken_bone'),
  z.literal('severed_hand'),
  z.literal('amputation_hand'),
  z.literal('crushed_hand'),
  z.literal('hyperextension_wrist'),
  z.literal('sprain_wrist'),
  z.literal('soreness'),
  z.literal('burn_scald'),
  z.literal('dermatitis'),
  z.literal('infection'),
]);
export type HandOptionKey = z.TypeOf<typeof handOptionKeyZod>;

export const headOptionKeyZod = z.union([
  z.literal('headache'),
  z.literal('concussion'),
  z.literal('skull'),
  z.literal('vision_loss'),
  z.literal('eye_pain'),
  z.literal('enucleation'),
  z.literal('eye_strain'),
  z.literal('infection_eyes'),
  z.literal('infection_ears'),
  z.literal('infection_jaw'),
  z.literal('foreignObjectInEye'),
  z.literal('hearing_loss'),
  z.literal('bruise_head'),
  z.literal('brain_bleed'),
  z.literal('whiplash'),
  z.literal('vertebrae_fracture'),
  z.literal('other_bone_head'),
  z.literal('neck_pain'),
  z.literal('burn_scald'),
  z.literal('cut_scrape'),
  z.literal('puncture'),
  z.literal('airbag_burns'),
  z.literal('light_bleeding'),
  z.literal('uncontrolled_bleeding'),
  z.literal('dislocated_jaw'),
  z.literal('broken_jaw'),
  z.literal('broken_teeth'),
  z.literal('lacerated_tongue'),
  z.literal('fracturedFacialBones'),
  z.literal('bruise'),
  z.literal('lipLaceration'),
  z.literal('dermatitis'),
  z.literal('infection_skin'),
  z.literal('throat_infection'),
  z.literal('lost_voice'),
  z.literal('asphyxiation'),
  z.literal('herniated_disk'),
]);
export type HeadOptionKey = z.TypeOf<typeof headOptionKeyZod>;

export const lowerLegOptionKeyZod = z.union([
  z.literal('cut_scrape'),
  z.literal('bruise'),
  z.literal('puncture'),
  z.literal('light_bleeding'),
  z.literal('uncontrolled_bleeding'),
  z.literal('dislocated_knee'),
  z.literal('hyperextension_knee'),
  z.literal('sprain_strain'),
  z.literal('soreness'),
  z.literal('acl_meniscus'),
  z.literal('broken_bone'),
  z.literal('bruised_bone'),
  z.literal('severed_lower_leg'),
  z.literal('amputation'),
  z.literal('crushed_lower_leg'),
  z.literal('burn_scald'),
  z.literal('dermatitis'),
  z.literal('infection'),
]);
export type LowerLegOptionKey = z.TypeOf<typeof lowerLegOptionKeyZod>;

export const shoulderOptionKeyZod = z.union([
  z.literal('cut_scrape'),
  z.literal('bruise'),
  z.literal('puncture'),
  z.literal('airbag_burns'),
  z.literal('dislocated'),
  z.literal('sprain_strain'),
  z.literal('hyperextension'),
  z.literal('soreness'),
  z.literal('broken_bone'),
  z.literal('bruised_bone'),
  z.literal('light_bleeding'),
  z.literal('uncontrolled_bleeding'),
  z.literal('burn_scald'),
  z.literal('dermatitis'),
  z.literal('infection'),
]);
export type ShoulderOptionKey = z.TypeOf<typeof shoulderOptionKeyZod>;

export const torsoOptionKeyZod = z.union([
  z.literal('cut_scrape'),
  z.literal('bruise'),
  z.literal('puncture'),
  z.literal('airbag_burns'),
  z.literal('seatbelt_bruise'),
  z.literal('light_bleeding'),
  z.literal('uncontrolled_bleeding'),
  z.literal('broken_rib'),
  z.literal('bruised_rib'),
  z.literal('hernia'),
  z.literal('genital_damage'),
  z.literal('soreness'),
  z.literal('rupture_spleen'),
  z.literal('rupture_liver'),
  z.literal('rupture_kidney'),
  z.literal('puncture_spleen'),
  z.literal('puncture_liver'),
  z.literal('puncture_kidney'),
  z.literal('burn_scald'),
  z.literal('dermatitis'),
  z.literal('infection'),
  z.literal('spleen_inflammation'),
  z.literal('spleen_laceration'),
  z.literal('spleen_infection'),
  z.literal('kidney_inflammation'),
  z.literal('kidney_laceration'),
  z.literal('kidney_infection'),
  z.literal('liver_inflammation'),
  z.literal('liver_laceration'),
  z.literal('liver_infection'),
]);
export type TorsoOptionKey = z.TypeOf<typeof torsoOptionKeyZod>;

export const upperLegOptionKeyZod = z.union([
  z.literal('cut_scrape'),
  z.literal('bruise'),
  z.literal('puncture'),
  z.literal('light_bleeding'),
  z.literal('uncontrolled_bleeding'),
  z.literal('dislocated_hip'),
  z.literal('hyperextension_hip'),
  z.literal('sprain_strain'),
  z.literal('soreness'),
  z.literal('broken_bone'),
  z.literal('bruised_bone'),
  z.literal('severed_upper_leg'),
  z.literal('amputation'),
  z.literal('crushed_upper_leg'),
  z.literal('burn_scald'),
  z.literal('dermatitis'),
  z.literal('infection'),
]);
export type UpperLegOptionKey = z.TypeOf<typeof upperLegOptionKeyZod>;
